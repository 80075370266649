<div class="modal-content" style="margin-top:0px;">

  <span class="close-icon" (click)="closeModal()">&#10005;</span>
  <div class="text-center message-container">
    <div class="row justify-content-center align-content-center align-items-center no-gutters mb-5">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <div class="counselor-text justify-content-center align-content-center align-items-center">
          <div class="media mx-auto provider-media">
            <img class="modal-icon" src="../../../../../../assets/icons/onlie-card-icon.png"
              alt="Online Counsling Icon">
          </div>
          <p class="modal-heading">{{BH_STRING}} Counseling</p>
          <p class="modal-sub-heading">Video, Phone, Chat, or Text</p>
          <p>
            <img class="concern-logo" src="../../../../../../assets/images/logo/concern-one-color-logo.png"
              alt="Concern Logo">
            <i class="fa fa-plus plus-icon" aria-hidden="true"></i>
            <img class="bh-logo" src="../../../../../../assets/icons/bh_logo_small.png" alt="BetterHelp Logo">
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="first-line">
    <div class="row first-left-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading">CONNECTING WITH YOUR COUNSELOR</p>
        <p class="about-bh">
          <strong>
          BetterHelp may connect you with another provider who meets your needs if the provider you selected is unavailable.
          </strong>
        </p>
      </div>
    </div>

    <div class="row first-right-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading">ONCE THERE, YOU WILL:</p>
        <ul class="bh-steps">
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Enter your email and answer some basic questions. It takes about 5 minutes.</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Create your FREE BetterHelp account.</p>
          </div>
          <div class="d-flex">
            <i class="fa fa-check-circle icon" aria-hidden="true"></i>
            <p>Schedule with your counselor.</p>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <br><br>
  <div class="second-line">
    <div class="row second-left-side">
      <div class="col-xs-12 col-md-12 col-xl-12">
        <p class="section-heading bh-section-heading">BETTERHELP SOLUTIONS</p>
        <div class="logos">
          <div class="child1">
            <div class="d-flex individuals">
              <p class="paratitle">For Individuals: </p>
              <img class="bh-help-logo" src="../../../../../../assets/icons/logo-betterhelp-small.png"
                alt="BetterHelp logo">
            </div>
            <div class="d-flex couples">
              <p>For Couples: </p>
              <img class="regain-help-logo" src="../../../../../../assets/icons/logo-Regain.png" alt="Regain Logo">
            </div>
            <div class="d-flex teens">
              <p>For Teens: </p>
              <img class="teens-help-logo" src="../../../../../../assets/icons/logo-teamcounseling.png"
                alt="Teen Counseling Logo">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
      <div class="accept-btn" (click)="accept()">
        OKAY, LETS’S GO! <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-12 col-md-12 col-xl-12 d-flex justify-content-center align-content-center align-items-center">
      <p class="cancel-link" (click)="closeModal()">
        I’M NOT READY TO LEAVE YET
      </p>
    </div>
  </div>

</div>
